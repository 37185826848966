export const GET_LIST = "GET_LIST";
export const GET_LIST_SUCCESS = "GET_LIST_SUCCESS";
export const GET_LIST_FAILED = "GET_LIST_FAILED";

export const GET_COUNT = "GET_COUNT";
export const GET_COUNT_SUCCESS = "GET_COUNT_SUCCESS";
export const GET_COUNT_FAILED = "GET_COUNT_FAILED";



export const GET_EXPORT = "GET_EXPORT";
export const GET_EXPORT_SUCCESS = "GET_EXPORT_SUCCESS";
export const GET_EXPORT_FAILED = "GET_EXPORT_FAILED";

export const SET_INVOICE_UPDATE = "SET_INVOICE_UPDATE";
export const SET_INVOICE_UPDATE_SUCCESS = "SET_INVOICE_UPDATE_SUCCESS";
export const SET_INVOICE_UPDATE_FAILED = "SET_INVOICE_UPDATE_FAILED";
export const SET_INVOICE_UPDATE_RESET = "SET_INVOICE_UPDATE_RESET";

export const GET_INVOICE_UPDATE = "GET_INVOICE_UPDATE";
export const GET_INVOICE_UPDATE_SUCCESS = "GET_INVOICE_UPDATE_SUCCESS";
export const GET_INVOICE_UPDATE_FAILED = "GET_INVOICE_UPDATE_FAILED";

export const REQ_INVOICE_UPDATE = "REQ_INVOICE_UPDATE";
export const REQ_INVOICE_UPDATE_SUCCESS = "REQ_INVOICE_UPDATE_SUCCESS";
export const REQ_INVOICE_UPDATE_FAILED = "REQ_INVOICE_UPDATE_FAILED";
export const REQ_INVOICE_UPDATE_RESET = "REQ_INVOICE_UPDATE_RESET";


export const SENT_INVOICE_CLIENT = "SENT_INVOICE_CLIENT";
export const SENT_INVOICE_CLIENT_SUCCESS = "SENT_INVOICE_CLIENT_SUCCESS";
export const SENT_INVOICE_CLIENT_FAILED = "SENT_INVOICE_CLIENT_FAILED";
export const GET_ENTITY = "GET_ENTITY";
export const GET_ENTITY_SUCCESS = "GET_ENTITY_SUCCESS";
export const GET_ENTITY_FAILED = "GET_ENTITY_FAILED";

export const GET_ENTITY_BRAND = "GET_ENTITY_BRAND";
export const GET_ENTITY_BRAND_SUCCESS = "GET_ENTITY_BRAND_SUCCESS";
export const GET_ENTITY_BRAND_FAILED = "GET_ENTITY_BRAND_FAILED";

export const GET_PHASE = "GET_PHASE";
export const GET_PHASE_SUCCESS = "GET_PHASE_SUCCESS";
export const GET_PHASE_FAILED = "GET_PHASE_FAILED";

export const CHANGE_PHASE = "CHANGE_PHASE";
export const CHANGE_PHASE_SUCCESS = "CHANGE_PHASE_SUCCESS";
export const CHANGE_PHASE_FAILED = "CHANGE_PHASE_FAILED";

export const GET_BRAND_PHASE = "GET_BRAND_PHASE";
export const GET_BRAND_PHASE_SUCCESS = "GET_BRAND_PHASE_SUCCESS";
export const GET_BRAND_PHASE_FAILED = "GET_BRAND_PHASE_FAILED";

export const GET_BRAND_PHASE_HISTORY = "GET_BRAND_PHASE_HISTORY";
export const GET_BRAND_PHASE_HISTORY_SUCCESS = "GET_BRAND_PHASE_HISTORY_SUCCESS";
export const GET_BRAND_PHASE_HISTORY_FAILED = "GET_BRAND_PHASE_HISTORY_FAILED";

export const UPLOAD_INVOICE_FILE = "UPLOAD_INVOICE_FILE";
export const UPLOAD_INVOICE_FILE_SUCCESS = "UPLOAD_INVOICE_FILE_SUCCESS";
export const UPLOAD_INVOICE_FILE_FAILED = "UPLOAD_INVOICE_FILE_FAILED";
export const UPLOAD_INVOICE_FILE_RESET = "UPLOAD_INVOICE_FILE_RESET";

export const GET_INVOICE_FILE = "GET_INVOICE_FILE";
export const GET_INVOICE_FILE_SUCCESS = "GET_INVOICE_FILE_SUCCESS";
export const GET_INVOICE_FILE_FAILED = "GET_INVOICE_FILE_FAILED";

export const GET_AGREEMENT_LOGS = "GET_AGREEMENT_LOGS";
export const GET_AGREEMENT_LOGS_SUCCESS = "GET_AGREEMENT_LOGS_SUCCESS";
export const GET_AGREEMENT_LOGS_FAILED = "GET_AGREEMENT_LOGS_FAILED";

  